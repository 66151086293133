import { Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import ArrowButton from "src/components/ArrowButton";
import Typography from "src/components/Typography";
// tslint:disable-next-line: no-relative-imports
import SubHeader from "../SubHeader";
import styles from "./Reviews.module.css";

type Props = {
  header: string;
  description: React.ReactNode;
  fluid: FluidObject;
  aspect: number;
  link: string;
};

const Reviews: React.SFC<Props> = (props) => {
  return (
    <div className={styles.blockWrapper}>
      <div className={styles.imgWrapper}>
        <Img fluid={{ ...props.fluid, aspectRatio: props.aspect }} />
      </div>
      <div className={styles.textWrapper}>
        <SubHeader text={props.header} />
        <div className={styles.reviewsDescription}>
          <Typography type="text">{props.description}</Typography>
        </div>
        <Link to={props.link}>
          <ArrowButton />
        </Link>
      </div>
    </div>
  );
};

export default Reviews;
