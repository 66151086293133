import React from "react";
import Typography from "src/components/Typography";
import styles from "./SubHeader.module.css";

const SubHeader = (props: { text: string }) => {
  return (
    <div className={styles.subHeaderWrapper}>
      <Typography type="h2">
        <h2>{props.text}</h2>
      </Typography>
    </div>
  );
};

export default SubHeader;
