import * as React from "react";
import Api from "src/api";
import { Baner as BanerType } from "src/types";
import Baner from "./Baner";

interface State {
  baner?: BanerType;
}

class BanerContainer extends React.Component<{ data?: BanerType }, State> {
  public state: State = {
    baner: this.props.data,
  };

  public async componentDidMount() {
    try {
      await this.getBaner();
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  }

  public render() {
    if (!this.state.baner?.isPublished) return null;

    return <Baner data={this.state.baner} />;
  }

  private getBaner = async () => {
    const res = await Api.getBaner();
    this.setState({ baner: res.data });
  };
}

export default BanerContainer;
