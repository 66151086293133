import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import BanerContainer from "./BanerContainer";

const StaticDataWrapper: React.SFC = () => {
  const data = useStaticQuery(query);
  return <BanerContainer data={data?.banerData?.data} />;
};

const query = graphql`
  query {
    banerData {
      data {
        _id
        header
        subheader
        text
        link
        isPublished
      }
    }
  }
`;

export default StaticDataWrapper;
