import * as React from "react";
import VideoPlayer from "src/components/VideoPlayer/VideoPlayer";
import placeholderImage from "src/images/main-placeholder.jpg";
import styles from "./Main.module.css";

const MainVideo: React.SFC = () => {
  return (
    <div className={styles.mainVideo}>
      <VideoPlayer
        link="https://vimeo.com/421002805/05d678f89f"
        videoId={421002805}
        name=""
        description=""
        previews={{
          // @ts-ignore
          m: { link: placeholderImage },
        }}
        videoOptions={{
          muted: true,
          autoplay: true,
          controls: true,
        }}
      />
    </div>
  );
};

export default MainVideo;
