import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import ArrowButton from "src/components/ArrowButton";
import Typography from "src/components/Typography";
import { User } from "src/types";

type ConnectStateProps = {
  user?: User;
};

interface Props {
  type;
  user;
}

interface State {
  putted: boolean;
}

class NotifyButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      putted: false,
    };
  }

  public render() {
    const { type } = this.props;
    if (
      typeof window !== `undefined` &&
      (window.localStorage.getItem(type) || this.state.putted)
    ) {
      return (
        <Typography type="biggerText">Вы подписались на уведомление</Typography>
      );
    }

    return (
      <div
        onClick={this.handleClick}
        role="button"
        style={{ cursor: "pointer" }}
      >
        <ArrowButton text="Подписаться на уведомления" />
      </div>
    );
  }

  public handleClick = async () => {
    const { type, user } = this.props;

    if (!user) {
      await navigate("/register", {
        state: {
          modal: true,
          noScroll: true,
          closeTo:
            typeof window !== `undefined` ? window.location.pathname : "/",
        },
      });
      return;
    }
    try {
      await Api.setReminder(type);
      if (typeof window !== `undefined`) {
        window.localStorage.setItem(type, "OK");
      }
      this.setState({ putted: true });
    } catch (error) {}
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(NotifyButton);
