import { Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import ArrowButton from "src/components/ArrowButton";
import Typography from "src/components/Typography";
import NotifyButton from "../NotifyButton";
// tslint:disable-next-line: no-relative-imports
import SubHeader from "../SubHeader";
import styles from "./Block.module.css";

type Props = {
  header: string;
  description: React.ReactNode;
  fluid: FluidObject;
  aspect: number;
  link?: string;
};

const Block: React.SFC<Props> = (props) => {
  return (
    <div className={styles.blockWrapper}>
      <div className={styles.headerWrapper}>
        <SubHeader text={props.header} />
      </div>
      <div className={styles.imgWrapper}>
        <Img
          className={styles.BlockImg}
          fluid={{ ...props.fluid, aspectRatio: props.aspect }}
          alt={props.header}
        />
      </div>
      <div className={styles.blockDescription}>
        <Typography type="text">{props.description}</Typography>
      </div>
      {props.link ? (
        <Link to={props.link}>
          <ArrowButton />
        </Link>
      ) : (
        <NotifyButton type={props.header} />
      )}
    </div>
  );
};

export default Block;
