import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import styles from "./Main.module.css";

import Baner from "src/components/Baner";
import PageHeader from "src/components/PageHeader/PageHeader";
import Block from "./Block/Block";
import MainVideo from "./MainVideo";
import Reviews from "./Reviews";
import Videos from "./Videos";

const Main: React.SFC = () => {
  const data = useStaticQuery(query);
  return (
    <div>
      <Baner />
      <MainVideo />
      <PageHeader text="Сервисы платформы" />
      <Reviews
        header="Рецензирование"
        description="Узнайте сильные и слабые стороны своего сценария, чтобы вывести его
            на новый уровень. Получите редакторское заключение в формате
            онлайн-консультации с продюсером или редактором. А если ваш сценарий
            нам понравится, мы будем рады обсудить сотрудничество и продвижение."
        fluid={data.reviews.childImageSharp.fluid}
        link="/send-scenario"
        aspect={2.105}
      />
      <Videos />
      <div className={styles.bottomWrapper}>
        <Block
          header="Конкурсы"
          description="Мы собрали все актуальные конкурсы в одном месте. Здесь питчинги Potential и мероприятия наших партнеров. Вам осталось только выбрать."
          fluid={data.contests.childImageSharp.fluid}
          aspect={2.105}
          link="/contests"
        />
        <Block
          header="Школа"
          description="Онлайн-образование для сценаристов, режиссеров и продюсеров. Полноценные курсы от профессионалов киноиндустрии, с домашними заданиями и личным куратором. Сервис в процессе создания."
          fluid={data.school.childImageSharp.fluid}
          link="/school"
          aspect={2.105}
        />
      </div>
      <Reviews
        header="Депонирование сценария"
        description={
          <div>
            <span>
              Защити свои права на сценарий за 1 день — быстро, онлайн и без
              бюрократии.
            </span>
            <br />
            <span>
              Депонирование сценария — это способ подтвердить своё авторство на
              указанную дату и время.
            </span>
            <br />
            <span>
              Процесс курирует наш штатный юрист, специализирующийся на работе в
              киноиндустрии.
            </span>
          </div>
        }
        fluid={data.depon.childImageSharp.fluid}
        aspect={2.105}
        link="https://potentialfilm.ru/copyright"
      />
    </div>
  );
};

export const query = graphql`
  query {
    reviews: file(name: { eq: "main_reviews" }) {
      childImageSharp {
        fluid(maxWidth: 767) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    depon: file(name: { eq: "main-depon" }) {
      childImageSharp {
        fluid(maxWidth: 767) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    school: file(name: { eq: "main_school" }) {
      childImageSharp {
        fluid(maxWidth: 767) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contests: file(name: { eq: "main_contest" }) {
      childImageSharp {
        fluid(maxWidth: 767) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Main;
