import * as React from "react";
import Typography from "src/components/Typography";
import eyeLogo from "src/images/baner-eye.svg";
import { Baner as BanerType } from "src/types";
import classes from "./Baner.module.css";
import ArrowButton from "../ArrowButton/ArrowButton";

interface Props {
  data: BanerType;
}

const Baner: React.SFC<Props> = ({ data }) => {
  return (
    <a
      className={[classes.container, "appWithContainer"].join(" ")}
      href={data.link}
    >
      <div className={classes.background} />
      <div className={classes.circles}>
        <div />
        <div />
        <div />
      </div>
      <div className={classes.bottomWrapper}>
        <div className={classes.whiteLines}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <ArrowButton />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.textContainer}>
          <Typography type="h3" black>
            {data.header}
          </Typography>
          <Typography type="h4" black>
            {data.subheader}
          </Typography>
          <Typography type="h4" black>
            {data.text}
          </Typography>
        </div>
        <div className={classes.eyeWrapper}>
          <img src={eyeLogo} />
        </div>
      </div>
    </a>
  );
};

export default Baner;
