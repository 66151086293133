import { Link } from "gatsby";
import React from "react";
import ArrowButton from "src/components/ArrowButton";
import RecommendedPlaylistsContainer from "src/components/RecommendedPlaylistsContainer";
import Typography from "src/components/Typography";
import { RECOMMENDED_PLAYLISTS_TYPES } from "src/consts";
// tslint:disable-next-line: no-relative-imports
import SubHeader from "../SubHeader";
import styles from "./Videos.module.css";

const Videos = () => {
  return (
    <div className={styles.blockWrapper}>
      <div className={styles.textWrapper}>
        <SubHeader text="Медиатека" />
        <Typography type="text">
          Здесь самые популярные мастер-классы и курсы из нашей Медиатеки!
          Прокачай свои знания вместе с Кино.Платформа Potential
        </Typography>
        <Link to="/medialibrary">
          <ArrowButton text="ПЕРЕЙТИ В МЕДИАТЕКУ" />
        </Link>
      </div>
      <div />
      <RecommendedPlaylistsContainer
        type={RECOMMENDED_PLAYLISTS_TYPES.MAIN_PAGE}
        renderTopVideo
      />
    </div>
  );
};

export default Videos;
