import { LocationContext } from "@reach/router";
import React from "react";
import Seo from "src/components/Seo";
import Main from "src/routes/Main";

const IndexPage = (props: LocationContext) => (
  <>
    <Seo pageTitle="Главная" />
    <Main />
  </>
);

export default IndexPage;
